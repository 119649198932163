import React from "react";

import CustomLink from "../custom-link/CustomLink";

const AcceptancePrivacyPolicy = ({className, classNameLink}: {className?: string, classNameLink?: string}) => {
  return (
    <p className={className || 'text-neutrals-40 text-xs lg:text-sm mt-3'}>
      *Korzystając z powyższych form kontaktu potwierdzasz zapoznanie się i&nbsp;akceptację
      <CustomLink
        className={classNameLink || 'underline ml-1 hover:text-neutrals-20 transition-colors'}
        isInternal to={'/regulaminy/polityka-prywatnosci'}>
        Polityki Prywatności
      </CustomLink>
    </p>
  )
}

export default AcceptancePrivacyPolicy;
