import React from "react";
import {GatsbyImage} from "gatsby-plugin-image";

import {GatsbyImageCustomType} from "../../../types/gatsby-image.type";
import CustomLink from "../../shared/custom-link/CustomLink";
import './slide.style.scss';

export type SlideProps = {
  image: GatsbyImageCustomType,
  label: string,
  url: string,
  green?: boolean | null,
  className?: string,
}

const Card = ({image, label, url, green, className}: SlideProps) => {
  return (
    <div className={className || 'relative text-center flex flex-col shrink-0 border border-neutrals-60 w-full xs:w-[320px] mr-auto ml-auto sm:w-[95%] h-[325px] bg-primary-60 p-3 p-8 z-10 noselect px-5'}>
      <p className={`uppercase text-left font-head text-xl 2xs:text-2xl ${green ? 'text-primary-60' : 'text-black'}`}>{label}</p>
      <CustomLink className={`mtr-btn-large h-12 ml-auto mt-auto rounded ${green ? 'mtr-btn-[primary]' : 'mtr-btn-[secondary]'}`} isInternal to={url} label={label}>
        <span>SPRAWDŹ</span>
        <span className={'ml-2 mtr-icon white !text-lg'}>arrow_forward</span>
      </CustomLink>
      <div className={'slide-card-gradient absolute top-0 left-0 z-[-1] w-full h-full pointer-events-none'}>
        <GatsbyImage className={'h-full w-full'} alt={image.alternativeText} image={image.localFile.childImageSharp.gatsbyImageData} />
      </div>
    </div>
  )
}

export default Card;
