import React from "react";
import {graphql, HeadProps, PageProps} from "gatsby";
import {SiteMetadata} from "../types/site-metadata.type";
import {IHelpSection} from "../components/templates/help/help-section.interface";
import {SiteMetadataConfigProps} from "../types/metadata-config-props.type";
import Card, {SlideProps} from "../components/monitoring/applications-image-slider/Card";
import TextFormatter from "../utils/TextFormatter";
import Layout from "../components/Layout";
import Help from "../components/templates/help/Help";
import {PageContextType} from "../types/page-context.type";
import StructuredData from '../assets/structured-data';

type ApplicationProps = {
  strapiApplication: {
    title: string,
    description: {
      data: {
        description: string
      }
    },
    siteMetadata: SiteMetadata,
    help: IHelpSection,
  },
  strapiApplicationImageSlider: {
    applications: SlideProps[]
  }
} & SiteMetadataConfigProps

const Applications = ({data: {strapiApplication, strapiApplicationImageSlider}, pageContext}: PageProps<ApplicationProps, PageContextType>) => {
  const formattedDescription = TextFormatter.concatOrphans(strapiApplication.description.data.description) || strapiApplication.description.data.description;
  const formattedTitle = TextFormatter.concatOrphans(strapiApplication.title) || strapiApplication.title;
  return(
    <Layout crumbs={pageContext.breadcrumb.crumbs} crumbLabel={strapiApplication.siteMetadata.crumbLabel}>
      <section className={'flex flex-col p-10 px-5 sm:px-10 xl:px-0 relative bg-neutrals-90 items-center overflow-visible pt-24'}>
          <div className={'flex flex-col justify-center items-center text-center '}>
            <h1 className={'font-head text-4xl xl:text-5xl text-black mb-4'} dangerouslySetInnerHTML={{__html: formattedTitle}}/>
            <div className={'text-neutrals-40 text-lg mb-12 max-w-[945px]'} dangerouslySetInnerHTML={{__html: formattedDescription}}/>
          </div>

          <div className={'grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 2xl:grid-cols-4 gap-5 z-10 overflow-visible'}>
            {strapiApplicationImageSlider.applications.map((card, index) => {
              return (
                <Card
                  key={index}
                  image={card.image}
                  label={card.label}
                  url={card.url}
                  green={card.green}
                  className={'relative text-center flex flex-col shrink-0 border border-neutrals-60 w-full sm:w-[260px] md:w-[300px] h-[325px] bg-primary-60 p-3 p-8 z-10 px-5'}
                />
              )
            })}
          </div>
          <div className={'bg-white absolute bottom-0 left-0 w-full h-3/4 z-0'} />
        </section>
        <Help helpSection={strapiApplication.help}/>
    </Layout>
  )
}

export default Applications;

export const Head = (props: HeadProps<ApplicationProps>) => {
  const slug = props.location.pathname;
  const siteUrl = props.data.site.siteMetadata.siteUrl;
  const {title, description, crumbLabel} = props.data.strapiApplication.siteMetadata;
  return (
    <>
      <title>{title}</title>
      <meta name="description" content={description}/>
      <meta name="robots" content="index, follow"/>
      <meta name="theme-color" content="#24B25B"/>
      {StructuredData([
        {
          name: crumbLabel,
          item: siteUrl + slug
        }
      ])}
    </>
  )
}

export const query = graphql`
  query {
    site {
      siteMetadata {
        siteUrl
        title
      }
    }
    strapiApplication {
      title
      siteMetadata {
        crumbLabel
        description
        title
      }
      help {
        title
        number
        email
        description
      }
      description {
        data {
          description
        }
      }
    }
    strapiApplicationImageSlider {
      applications {
        url
        label
        green
        image {
          alternativeText
          localFile {
            childImageSharp {
              gatsbyImageData(layout: CONSTRAINED, placeholder: BLURRED)
            }
          }
        }
      }
    }
  }
`
