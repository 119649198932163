import React from "react";

import {IHelpSection} from "./help-section.interface";
import CustomLink from "../../shared/custom-link/CustomLink";
import ContactInformation from '../../../assets/contact-information';
import AcceptancePrivacyPolicy from "../../shared/acceptance-privacy-policy/AcceptancePrivacyPolicy";

interface IHelpSectionProps {
  helpSection: IHelpSection,
  className?: string,
}

const Help = ({ helpSection, className }: IHelpSectionProps) => {
  const {title, description} = helpSection;

  return(
    <section className={className || 'flex flex-col justify-center text-center items-center bg-white px-5 py-20 md:py-36'}>
      <p className={'text-3xl sm:text-4xl xl:text-5xl font-head mb-2.5'}>{title}</p>
      <p className={'text-base sm:text-lg text-neutrals-20 mb-3 sm:mb-6'}>{description}</p>
      <div className={'flex flex-col xs:flex-row items-center gap-3.5'}>
        <CustomLink isInternal={false} className={'mtr-btn lg:mtr-btn-large mtr-btn-[secondary] !text-white'} to={`tel:${ContactInformation.phoneSupport}`}>
          <span>{ContactInformation.phoneSupport}</span>
        </CustomLink>
        <CustomLink isInternal={false} className={'mtr-btn lg:mtr-btn-large mtr-btn-outline-[secondary] uppercase'} to={`mailto:${ContactInformation.emailSupport}`}>
          <span>{ContactInformation.emailSupport}</span>
        </CustomLink>
      </div>
      <AcceptancePrivacyPolicy/>
    </section>
  )
}

export default Help;
